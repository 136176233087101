import React, { memo } from 'react';

const GRADIENTS = {
  red: (
    <>
      <radialGradient id="red_1_" cx="4370.0122" cy="-11893.2686" r="285.9045" gradientTransform="matrix(-3.634427e-02 2.875958e-02 -2.488205e-02 -3.144414e-02 -127.5495 -490.8005)" gradientUnits="userSpaceOnUse">
        <stop offset="0.6894" stopColor="#BA0000" />
        <stop offset="0.986" stopColor="#940000" />
      </radialGradient>
    </>
  ),
  blue: (
    <>
      <radialGradient id="blue_1_" cx="4370.3022" cy="-11892.7715" r="285.8708" gradientTransform="matrix(-3.634427e-02 2.875958e-02 -2.488205e-02 -3.144414e-02 -127.5018 -490.7835)" gradientUnits="userSpaceOnUse">
        <stop offset="0.4287" stopColor="#1F3FDB" />
        <stop offset="0.9898" stopColor="#0B2096" />
      </radialGradient>
    </>
  ),
  yellow: (
    <>
      <radialGradient id="yellow_1_" cx="4370.0552" cy="-11893.0928" r="286.1882" gradientTransform="matrix(-3.634427e-02 2.875958e-02 -2.488205e-02 -3.144414e-02 -127.537 -490.7911)" gradientUnits="userSpaceOnUse">
        <stop offset="0.4063" stopColor="#F8D400" />
        <stop offset="0.9898" stopColor="#DBA927" />
      </radialGradient>
    </>
  ),
  green: (
    <>
      <radialGradient id="green_1_" cx="4370.0552" cy="-11893.0928" r="286.1882" gradientTransform="matrix(-3.634427e-02 2.875958e-02 -2.488205e-02 -3.144414e-02 -127.537 -490.7911)" gradientUnits="userSpaceOnUse">
        <stop offset="0.4771" stopColor="#159B1F" />
        <stop offset="0.7011" stopColor="#0E8019" />
        <stop offset="0.9898" stopColor="#066212" />
      </radialGradient>
    </>
  ),
};

const GradientComponent = memo(({ color }) => {
  return <>{GRADIENTS[color]}</>;
});

export default function MarblesColor({ color }) {
  if (!GRADIENTS[color]) {
    console.warn(`Unknown marble color: ${color}`);
    return null;
  }

  return (
    <svg width="100" height="100">
      <defs>
        <GradientComponent color={color} />
      </defs>
      <circle cx="50" cy="50" r="40" fill={`url(#${color}_1_)`} />
    </svg>
  );
}
