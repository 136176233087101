import * as styles from '../../styles/scss/components/NoCardsModal/NoCardsModal.module.scss'
import { cdnLoader } from '../../config/cdnImageLoader.config';
import { forwardRef } from 'react';

const NoCardsModal = forwardRef(({device, text}, ref) => {
  
  return (
    <div className = {`${styles.no_cards_outer} ${styles[device]}`} ref = {ref}>
      <img className = {styles.no_cards_image} src={cdnLoader('/images/gameModal/noCards.svg')} />
      <span className = {styles.no_cards_text}>{text}</span>
    </div>
  )
})

export default NoCardsModal