import { useEffect, useState, memo } from 'react';
import * as styles from '../../styles/scss/components/Avatar/Avatar.module.scss'
import EditSvg from '../../public/images/common/edit.svg';
import CheckSvg from '../../public/images/common/check.svg';
import { EditButton } from '../Buttons/Buttons';
import Loader from '../CustomLoader/Loader';
import InactiveSvg from '../../public/images/common/player-inactive.svg'
import OfflineSvg from '../../public/images/common/disconnected.svg';
import LeftSvg from '../../public/images/common/leave-red.svg';
import { cdnLoader } from '../../config/cdnImageLoader.config';

const Avatar = (props) => {
  const defaultState = {
    inGameStatus: '', //status could be: normal, active,
    onlineStatus: '', 
    size: 'medium', //size could be: big(128px x 128px), medium (64px x 64px), small(32px x 32px), extraSmall(24px, 24px)
    avatarUrls: null, //urls of avatars
    mode: 'normal', // mode can be normal and stealing
    hasFlag: true,
    color: '',
    showEditIcon: false,
    country: '',
    active: false,
    showOverlayOnHover: false,
    showLodaingStatus: false,
    showLoader: false,
    loaderSize: '',
    customImage: false,
    false: true,
    glow: false,
    editButtonDisabled: false,
    playersTurn: false,
    hasShadow: false,
    avatarName: '',
    inGameAcitivityStatus: null
  };

  const state = {...defaultState, ...props};

  const getPlayerStatusUi = () => {
    if (state.inGameAcitivityStatus === 'player:afk') {
      return <InactiveSvg />
    } else if (state.inGameAcitivityStatus === 'player:disconnected'){
      return <OfflineSvg />
    } else if (state.inGameAcitivityStatus === 'player:left') {
      return <LeftSvg />
    } else if (state.inGameAcitivityStatus === 'playing') {
      return ''
    }
  }

  const [urls, setUrls] = useState({})
  useEffect(() => {
    if (!!state.avatarUrls) {
      const urls = {
        normal: state.avatarUrls.normal?.replace('192.168.1.10', '178.134.187.46'),
        stealing: state.avatarUrls.stealing?.replace('192.168.1.10', '178.134.187.46')
      }
      if (!state.avatarUrls.normal) {
        urls.normal = cdnLoader('/images/default_normal.png')
        urls.stealing = cdnLoader('/images/Dobermansteal.png')
      }
      setUrls(urls)
    }
  }, [state.avatarUrls])

  return (

    <div className={`${styles.avatar} ${(state.showOverlayOnHover && styles.showHover)}  ${(state.active && styles.active)}`}>
      <div className={`${styles.image_holder} ${styles[state.size]} ${styles[state.inGameStatus]}  ${state.glow ? styles.glow : ''} ${styles[state.color]} ${(state.customImage && styles.custom_image)} ${(state.hasShadow && styles.has_shadow)}`} >
        {state.onlineStatus && <div className={`${styles.inner_circle} ${styles[state.onlineStatus]}`}></div>}
        <img
          src={cdnLoader(urls[state.mode])}
        />
        {state.inGameAcitivityStatus && state.inGameAcitivityStatus !== 'playing' && (
          <div className={`${styles.inGameAcitivityStatus} ${styles[state.inGameAcitivityStatus]}`}>
            {getPlayerStatusUi()}
          </div>
        )}
      </div>
      {state.hasFlag && (
        <div className={`${styles.country_image_holder} ${styles[state.size]}`}>
          <img
            className= "country_image"
            src={cdnLoader(`/images/flags/${state.country}.svg`)}
          />
        </div>
      )}
      <div className = {`${styles.choose_avatar_overlay} ${styles[state.size]}`}>
        <CheckSvg />
      </div>
      {state.showEditIcon && (
        <div className = {`${styles.edit_icon} ${state.editButtonDisabled ? styles.disabled : ''}`}>
          <EditButton/>
        </div>
      )}
      {state.showLodaingStatus && (
        <div className = {`${styles.loading_status} ${styles[state.loaderSize]}`}>
          {state.showLoader ? <Loader loaderSize = {state.loaderSize}/> : <div className = {styles.joined}><CheckSvg /></div>}
        </div>
      )}
      {state.isOnline && <div className={styles.online_dot}></div>}    
      {
        state.avatarName && (
          <span className={styles.avatar_username}>{state.avatarName}</span>
        )
      }
    </div>
  )
}

export default Avatar