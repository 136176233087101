
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import Layout from '../layout/index'
import '../styles/globals.css'
import '../styles/scss/pages/userProfile/index.scss';
import store from '../store/store'
import { Provider } from 'react-redux'
import '../styles/transitionsCss/transitions.css';
import Head from 'next/head';
import { useRouter } from 'next/router';

import Landing from '../components/MetaLanding/MetaLading';
function MyApp(props) {
  const router = useRouter();
  if(typeof window === 'undefined') {
    const {
      meta_title,
      meta_description,
      header, 
      subHeader, 
      login_button, 
      signup_button, 
      login_email_or_name_input, 
      password_input,
      forget_password,
    } = props.pageProps
    const locale = props.pageProps.__lang
    return <Landing
      textContent = {{
        meta_title,
        meta_description,
        header, 
        subHeader, 
        login_button, 
        signup_button, 
        login_email_or_name_input, 
        password_input,
        forget_password,
        locale: locale
      }}
    />
  }
  const {
    Component,
    pageProps
  } = props

  
  return (
    <Provider store={store}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no" />
        <meta name="loadforge-site-verification" content="8b520d802ba3c03235ecb76c83e35d480147df95f93c00bbd67eef8fac643d28b5046704324c532e9cf368bb658be18fcc092419c4cbf15749af40c0853f1952" />
        <meta property="og:image" content={`https://cdn.stage.braendi-dog.online/images/meta/${router.locale.toUpperCase()}.jpg`} />
        <meta name="thumbnail" content={`https://cdn.stage.braendi-dog.online/images/meta/${router.locale.toUpperCase()}.jpg`} />
        <meta name="x-apple-disable-message-reformatting" />
      </Head>
      <Layout 
        title = {pageProps.__namespaces.meta.meta_title}
        description = {pageProps.__namespaces.meta.meta_description}
        >
        <Component {...pageProps} />
      </Layout>
    </Provider>
  )
}

export async function getStaticProps({ locale }) {
  const t = await getT(locale, 'meta')
  return { props: {data: {
    meta_title: t('meta_title'), 
    meta_description: t('meta_description'), 
    header: t('header'), 
    subHeader: t('subHeader'), 
    login_button: t('login_button'), 
    signup_button: t('signup_button'), 
    login_email_or_name_input: t('login_email_or_name_input'), 
    password_input: t('password_input'),
    forget_password: t('forget_password'),
    locale
  }}
}}


const __Page_Next_Translate__ = MyApp 

    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  